

import axios from '@/axios.js'

export default {
  setChatSearchQuery ({ commit }, query) {
    commit('SET_CHAT_SEARCH_QUERY', query)
  },
  updateAboutChat ({ commit, rootState }, value) {
    commit('UPDATE_ABOUT_CHAT', {
      rootState,
      value
    })
  },
  updateStatusChat ({ commit, rootState }, value) {
    commit('UPDATE_STATUS_CHAT', {
      rootState,
      value
    })
  },
  
  fetchChat ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/chat-user/${payload.user_id}/${payload.type}`)
        .then((response) => {
          commit('UPDATE_CHAT', response.data.data)
          commit('MARK_SEEN_ALL_MESSAGES', payload.user_id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // API CALLS
  sendChatMessage ({ commit }, payload) {
    // getters, dispatch, 
    return new Promise((resolve, reject) => {
      axios.post('/chat-send', payload)
      .then((response) => {
        //payload.chatData = getters.chatDataOfUser(payload.id)
        //if (!payload.chatData) { dispatch('fetchChatContacts') }
        //commit('SEND_CHAT_MESSAGE', payload)
        commit('SEND_CHAT_MESSAGE', response.data.data)
        resolve(response)
      })
      .catch((error) => { reject(error) })
    })
  },

  // Get contacts from server. Also change in store
  fetchContacts ({ commit }, type) {
    return new Promise((resolve, reject) => {
      axios.get(`/kontak/${type}`)
        .then((response) => {
          commit('UPDATE_CONTACTS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Get chats from server. Also change in store
  fetchChats ({ commit }, type) {
    return new Promise((resolve, reject) => {
      axios.get(`/chat/1/${type}`)
        .then((response) => {
          commit('UPDATE_CHATS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Get chat-contacts from server. Also change in store
  fetchChatContacts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/kontak', {params: {q: ''}})
        .then((response) => {
          commit('UPDATE_CHAT_CONTACTS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  /*
  markSeenAllMessages ({ getters, commit }, id) {
    return new Promise((resolve, reject) => {
      axios.post('/api/apps/chat/mark-all-seen', {id})
        .then((response) => {
          commit('MARK_SEEN_ALL_MESSAGES', {
            id,
            chatData: getters.chatDataOfUser(id)
          })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  */
  markSeenAllMessages ({ commit }, logs, userId) {
    return new Promise((resolve, reject) => {
      axios.post('/mark-all-seen', logs)
      .then((response) => {
        commit('MARK_SEEN_ALL_MESSAGES', userId)
        resolve(response)
      })
      .catch((error) => { reject(error) })
    })
  },

  toggleIsPinned ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/apps/chat/set-pinned/', {contactId: payload.id, value: payload.value})
      .then((response) => {
        commit('TOGGLE_IS_PINNED', payload)
        resolve(response)
      })
      .catch((error) => { reject(error) })
    })
  }
}

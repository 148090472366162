

export default {

  chat: {},

  // Chat Search Query
  chatSearchQuery: '',

  // Stores All Contacts
  contacts: [],

  // Stores Chat Contacts
  chatContacts: [],

  // Stores Chat data(log)
  chats: {}
}


//import Vue from 'vue'

export default {
  UPDATE_ABOUT_CHAT (state, obj) {
    obj.rootState.AppActiveUser.about = obj.value
  },
  UPDATE_STATUS_CHAT (state, obj) {
    obj.rootState.AppActiveUser.status = obj.value
  },

  UPDATE_CHAT (state, chat) {
    state.chat = chat
  },

  // API AFTER
  SEND_CHAT_MESSAGE (state, payload) {
    /*
    if (payload.chatData) {
      // If there's already chat. Push msg to existing chat
      state.chats[Object.keys(state.chats).find(key => Number(key) === payload.id)].msg.push(payload.msg)
    } else {
      // Create New chat and add msg
      const chatId = payload.id
      Vue.set(state.chats, [chatId], { isPinned: payload.isPinned,
        msg: [payload.msg] })
    }
    */
    state.chat.push(payload)
  },
  UPDATE_CONTACTS (state, contacts) {
    state.contacts = contacts.sort((a, b) => (a.chat_last_date > b.chat_last_date) ? -1 : 1)
    //.sort(function(a, b) { return parseFloat(a.chat_last_date) - parseFloat(b.chat_last_date); })
    //contacts.sort((a, b) => (a.chat_last_date > b.chat_last_date) ? -1 : 1)
  },
  UPDATE_CHAT_CONTACTS (state, chatContacts) {
    state.chatContacts = chatContacts
  },
  UPDATE_CHATS (state, chats) {
    state.chats = chats
  },
  SET_CHAT_SEARCH_QUERY (state, query) {
    state.chatSearchQuery = query
  },
  SET_NULL_CONTACTS (state) {
    state.contacts = []
  },
  /*
  MARK_SEEN_ALL_MESSAGES (state, userId) {
    payload.chatData.msg.forEach((msg) => {
      msg.isSeen = true
    })
  },
  */
  MARK_SEEN_ALL_MESSAGES (state, userId) {
    const contactIndex = state.contacts.findIndex((p) => p.uid === userId)
    state.contacts[contactIndex].badge = 0
  },

  TOGGLE_IS_PINNED (state, payload) {
    state.chats[Object.keys(state.chats).find(key => Number(key) === payload.id)].isPinned = payload.value
  }
}


<template>
    <div class="chat__contact flex items-center px-2 pt-4 pb-2" :class="{'bg-primary-gradient text-white shadow-lg': isActiveChatUser}">
        <div class="contact__avatar mr-1">
            <vs-avatar class="border-2 border-solid border-white" :src="getImage('/profil/'+contact.photoURL)" size="42px" />
        </div>
        <div class="contact__container w-full flex items-center justify-between overflow-hidden">
            <div class="contact__info flex flex-col truncate w-5/6">
                <h5 class="font-semibold" :class="{'text-white': isActiveChatUser}">{{ contact.displayName }}</h5>
                
                <span class="truncate" v-if="contact.chat_last_chat">{{ contact.chat_last_chat  }}</span>
                <!--
                <span class="truncate" v-else>{{ contact.about }}</span>
                -->
                
            </div>

            <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
                
                <span class="whitespace-no-wrap">{{ toDate (contact.chat_last_date) | date }}</span>
                <vs-chip class="number" color="secondary" v-if="contact.badge > 0">{{ contact.badge }}</vs-chip>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    contact          : { type: Object,  required: true },
    isActiveChatUser : { type: Boolean }
  },
  methods: {
    toDate (time) {
        if (time) {
            return new Date(Date.parse(time))
        } else {
            return ''
        }
    },
  }
}
</script>



<template>
  <div id="chat-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
      
      <vs-sidebar class="items-no-padding" parent="#chat-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isChatSidebarActive" id="chat-list-sidebar">

          <!-- USER PROFILE SIDEBAR -->
          
          <user-profile 
          :active="activeProfileSidebar" 
          :userProfile="userProfile" 
          class="user-profile-container" :isLoggedInUser="isLoggedInUserProfileView" @closeProfileSidebar="closeProfileSidebar">
          </user-profile>
          
          
          <div class="chat__profile-search flex p-4">
              <div class="relative inline-flex">
                <vs-avatar v-if="activeUser.avatar" class="m-0 border-2 border-solid border-white" :src="getImage('/profil/'+this.activeUser.avatar)" size="40px" @click="showProfileSidebar(activeUser, true)" />
                <!-- 
                <div class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0" :class="'bg-' + getStatusColor(true)"></div> 
                -->
              </div>
              <vs-input icon-no-border icon="icon-search" icon-pack="feather" class="w-full mx-5 input-rounded-full" placeholder="Search or start a new chat" v-model="searchQuery"/>

              <feather-icon class="md:inline-flex lg:hidden -ml-3 cursor-pointer" icon="XIcon" @click="toggleChatSidebar(false)" />
          </div>

          <vs-divider class="d-theme-border-grey-light m-0" />
          <component :is="scrollbarTag" class="chat-scroll-area" :settings="settings" :key="$vs.rtl">

              <!-- CONTACTS LIST -->
              <div class="chat__contacts">
                  <ul class="chat__contacts bordered-items">
                      <li class="cursor-pointer" v-for="contact in contacts" :key="contact.uid" @click="updateActiveChatUser(contact.uid)">
                          <chat-contact :contact="contact"></chat-contact>
                      </li>
                  </ul>
              </div>
          </component>
      </vs-sidebar>

      <!-- RIGHT COLUMN -->
      <div class="chat__bg no-scroll-content chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0 vs-con-loading__container" :class="{'sidebar-spacer--wide': clickNotClose, 'flex items-center justify-center': activeChatUser === null}" id="chat-load">
          <template v-if="activeChatUser">
              <div class="chat__navbar">
                  <chat-navbar 
                  :isSidebarCollapsed="!clickNotClose" :user-id="activeChatUser" :isPinnedProp="isChatPinned" @openContactsSidebar="toggleChatSidebar(true)" @showProfileSidebar="showProfileSidebar" @toggleIsChatPinned="toggleIsChatPinned"></chat-navbar>
              </div>
              <component :is="scrollbarTag" class="chat-content-scroll-area border border-solid d-theme-border-grey-light" :settings="settings" ref="chatLogPS" :key="$vs.rtl">
                  <div class="chat__log" ref="chatLog">
                      <chat-log :userId="activeChatUser" v-if="activeChatUser"></chat-log>
                  </div>
              </component>
              <div class="chat__input flex p-4 bg-white">
                  <vs-input class="flex-1" placeholder="Type Your Message" v-model="typedMessage" @keyup.enter="sendMsg" />
                  <vs-button class="bg-primary-gradient ml-4" type="filled" @click="sendMsg">Send</vs-button>
              </div>
          </template>
          <template v-else>
              <div class="flex flex-col items-center">
                  <feather-icon icon="MessageSquareIcon" class="mb-4 bg-white p-8 shadow-md rounded-full" svgClasses="w-16 h-16"></feather-icon>
                  <h4 class=" py-2 px-4 bg-white shadow-md rounded-full cursor-pointer" @click.stop="toggleChatSidebar(true)">Start Conversation</h4>
              </div>
          </template>
      </div>
  </div>
</template>

<script>
import ChatContact         from './ChatContact.vue'
import ChatLog             from './ChatLog.vue'
import ChatNavbar          from './ChatNavbar.vue'
import UserProfile         from './UserProfile.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moduleChat          from '@/stores/chat/moduleChat.js'

import Pusher from 'pusher-js'

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      active               : true,
      isHidden             : false,
      searchContact        : '',
      activeProfileSidebar : false,
      activeChatUser       : null,
      userProfile          : {},
      typedMessage         : '',
      isChatPinned         : false,
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70
      },
      clickNotClose        : true,
      isChatSidebarActive  : true,
      isLoggedInUserProfileView: false,

      sendMsgStatus : false
    }
  },
  watch: {
    chatFilter () {
      this.activeChatUser = null
      this.$store.commit('chat/SET_NULL_TASKS')
      this.$store.dispatch('chat/fetchContacts', this.$route.params.type)
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    ...mapGetters({
      activeUser : 'auth/user',
    }),
    /*
    chatLastMessaged () {
      return (userId) => this.$store.getters['chat/chatLastMessaged'](userId)
    },
    */
    chatUnseenMessages () {
      return (userId) => {
        const unseenMsg = this.$store.getters['chat/chatUnseenMessages'](userId)
        if (unseenMsg) return unseenMsg
      }
    },
    /*
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    */
    chatFilter () {
      return this.$route.params.type
    },
    getStatusColor () {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser)

        if (userStatus === 'online') {
          return 'success'
        } else if (userStatus === 'do not disturb') {
          return 'danger'
        } else if (userStatus === 'away') {
          return 'warning'
        } else {
          return 'grey'
        }
      }
    },
    contacts () {
      return this.$store.getters['chat/contacts']
    },
    searchQuery: {
      get () {
        return this.$store.state.chat.chatSearchQuery
      },
      set (val) {
        this.$store.dispatch('chat/setChatSearchQuery', val)
      }
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    isActiveChatUser () {
      return (userId) => userId === this.activeChatUser
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    getUserStatus (isActiveUser) {
      // return "active"
      return isActiveUser ? this.$store.state.AppActiveUser.status : this.contacts[this.activeChatUser].status
    },
    closeProfileSidebar (value) {
      this.activeProfileSidebar = value
    },
    updateActiveChatUser (contactId) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#chat-load',
        type: 'point',
        scale: 0.5
      })

      this.activeChatUser = contactId
      const payload = {
        'type' : this.$route.params.type,
        'user_id': contactId
      }
      this.$store.dispatch('chat/fetchChat', payload)

      setTimeout(() => {
        this.$vs.loading.close('#chat-load > .con-vs-loading')
        //let logs = [this.$store.state.chat.chat]
        //this.$store.dispatch('chat/markSeenAllMessages', logs, contactId)
      }, 2000)
      /*
      if (this.$store.getters['chat/chatDataOfUser'](this.activeChatUser)) {
        this.$store.dispatch('chat/markSeenAllMessages', contactId)
      }
      */
      //const chatData = this.$store.getters['chat/chatDataOfUser'](this.activeChatUser)
      //if (chatData) this.isChatPinned = chatData.isPinned
      //else this.isChatPinned = false
      this.toggleChatSidebar()
      this.typedMessage = ''
    },
    showProfileSidebar (user, openOnLeft = false) {
      console.log(user)
      this.userProfile = user
      this.isLoggedInUserProfileView = openOnLeft
      this.activeProfileSidebar = !this.activeProfileSidebar
    },
    sendMsg () {
      if (!this.typedMessage) return
      const payload = {
        'type' : this.$route.params.type,
        'textContent' : this.typedMessage,
        'id': this.activeChatUser
      }
      this.$store.dispatch('chat/sendChatMessage', payload)
      this.typedMessage = ''

      const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS
      scroll_el.scrollTop = this.$refs.chatLog.scrollHeight

      this.sendMsgStatus = true
    },
    toggleIsChatPinned (value) {
      this.isChatPinned = value
    },
    setSidebarWidth () {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false
      } else {
        this.isChatSidebarActive = this.clickNotClose = true
      }
    },
    toggleChatSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isChatSidebarActive = value
    },
    chatUpdate () {
      let pusher = new Pusher('35cfb16cee052b08fd44', { cluster: 'ap1', forceTLS: true })
      pusher.subscribe('bacce')
      pusher.bind('chat-update', data => {
        if (!this.sendMsgStatus) {
          //console.log(this.sendMsgStatus)
          this.$store.commit('chat/SEND_CHAT_MESSAGE', data.update)
          this.sendMsgStatus = false
        }
      })
      //if (!this.sendMsgStatus) {}
    },
  },
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatNavbar,
    ChatLog
  },
  created () {
    this.$store.registerModule('chat', moduleChat)
    this.$store.dispatch('chat/fetchContacts', this.$route.params.type)
    //this.$store.dispatch('chat/fetchChatContacts')
    //this.$store.dispatch('chat/fetchChats', this.$route.params.type)
    this.setSidebarWidth()

    // PUSHER
    this.chatUpdate()
  },
  beforeDestroy () {
    this.$store.unregisterModule('chat')
  },
  mounted () {
    this.$store.dispatch('chat/setChatSearchQuery', '')
  }
}

</script>


<style lang="scss">
@import "@/assets/scss/vuexy/apps/chat.scss";
</style>

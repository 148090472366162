
<template>
    <div id="component-chat-log" class="m-8" v-if="chatData">
        <div v-for="(msg, index) in chatData" class="msg-grp-container" :key="index">

            <!-- If previous msg is older than current time -->
            <template v-if="chatData[index-1]">
                <vs-divider v-if="!isSameDay(msg.time, chatData[index-1].time)" class="msg-time">
                    <span>{{ toDate(msg.time) }}</span>
                </vs-divider>
                <div class="spacer mt-8" v-if="!hasSentPreviousMsg(toStatus(chatData[index-1].isSent), toStatus(msg.isSent))"></div>
            </template>

            <div class="flex items-start" :class="[{'flex-row-reverse' : toStatus(msg.isSent)}]">

                <template v-if="chatData[index-1]">
                    <template v-if="(!hasSentPreviousMsg(toStatus(chatData[index-1].isSent), toStatus(msg.isSent)) || !isSameDay(msg.time, chatData[index-1].time))">
                        <vs-avatar size="40px" class="border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="toStatus(msg.isSent) ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'" :src="senderImg(toStatus(msg.isSent))"></vs-avatar>
                    </template>
                </template>

                <template v-if="index == 0">
                    <vs-avatar size="40px" class="border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="toStatus(msg.isSent) ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'" :src="senderImg(toStatus(msg.isSent))"></vs-avatar>
                </template>

                <template v-if="chatData[index-1]">
                    <div class="mr-16" v-if="!(!hasSentPreviousMsg(toStatus(chatData[index-1].isSent), toStatus(msg.isSent)) || !isSameDay(msg.time, chatData[index-1].time))"></div>
                </template>

                <div class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm" :class="{'bg-primary-gradient text-white': toStatus(msg.isSent), 'border border-solid border-transparent bg-white': !toStatus(msg.isSent)}">
                    <span>{{ msg.textContent }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default{
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      activeUser : 'auth/user',
    }),
    chatData () {
      //console.log(this.$store.getters['chat/chatDataOfUser'](this.userId))
      //return this.$store.getters['chat/chatDataOfUser'](this.userId)
      //console.log(this.$store.state.chat)
      return this.$store.state.chat.chat
    },
    senderImg () {
      return (isSentByActiveUser) => {
        if (isSentByActiveUser) return this.getImage('/profil/'+ this.activeUser.avatar)
        else return this.getImage('/profil/'+this.activeUser.avatar)
      }
    },
    hasSentPreviousMsg () {
      return (last_sender, current_sender) => last_sender === current_sender
    }
  },
  methods: {
    isSameDay (time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to))
      const date_time_from = new Date(Date.parse(time_from))
      return date_time_to.getFullYear() === date_time_from.getFullYear() && date_time_to.getMonth() === date_time_from.getMonth() && date_time_to.getDate() === date_time_from.getDate()
    },
    toStatus (status) {
      if (status === 'true') {
        return true
      } else {
        return false
      }
    },
    toDate (time) {
      const locale = 'en-us'
      const date_obj = new Date(Date.parse(time))
      const monthName = date_obj.toLocaleString(locale, {
        month: 'short'
      })
      return `${date_obj.getDate()  } ${   monthName}`
    },
    scrollToBottom () {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight
      })
    }
  },
  updated () {
    this.scrollToBottom()
  },
  mounted () {
    this.scrollToBottom()
  }
}
</script>
